import React, { useState } from 'react'
import Map from './Map'

import floorDict from '../floorDict.json'
import config from '../ecc-config.json'

const MapView = ({ tenants, categories, isMobile, goToTenant }) => {
  const [activeFloor, setActiveFloor] = useState('MainFloor')
  const [activeTenant, setActiveTenant] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const [showList, setShowList] = useState(false)

  const changeTenant = (suitenum) => {
    const matchingTenant = tenants.find(t => {
      const suiteVal = t.suite.textfields.suitenum || t.suite.textfields.label_id
      return `${suiteVal}`.toLowerCase() === `${suitenum}`.toLowerCase()
    })

    if (!matchingTenant) {
      return
    }

    const floorValue = floorDict[matchingTenant.floor.textfields.label_id]

    setShowList(false)
    setActiveFloor(floorValue)
    setActiveTenant(`${suitenum}`)
  }

  const clickHandler = (mesh) => {
    const {
      name
    } = mesh

    const configData = config.shops.find(s => s.assetId === name)

    if (!configData || !configData.cmsID) {
      return
    }

    changeTenant(configData.cmsID)

    if (isMobile) {
      
      const matchingTenant = tenants.find(t => {
        const suiteVal = t.suite.textfields.suitenum || t.suite.textfields.label_id
        return `${suiteVal}`.toLowerCase() === `${configData.cmsID}`.toLowerCase()
      })

      if (!matchingTenant) {
        return
      }

      setShowList(true)
      setSearchTerm(matchingTenant.name)
    }

    const listElement = document.querySelector('.tenant-button.active')
    
    if (!listElement) {
      return
    }

    listElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  const changeFloor = (floor) => {
    setShowList(false)
    setActiveTenant('')
    setActiveFloor(floor)
  }

  const floors = [
    {
      name: 'ThirdFloor',
      displayName: 'Top Level'
    },
    {
      name: 'SecondFloor',
      displayName: 'Pedway Level'
    },
    {
      name: 'MainFloor',
      displayName: 'Street Level'
    },
    {
      name: 'ConcourseFloor',
      displayName: 'Lower Level'
    }
  ]

  const list = tenants.reduce((acc, curr) => {
    const {
      categories
    } = curr

    const c = categories[0]

    if (!acc[c]) {
      acc[c] = []
    }

    acc[c].push(curr)

    return acc
  }, {})

  categories.push({
    id: 'undefined',
    name: 'Other'
  })

  const sidebar = (
    <div className="sidebar">
      <div className="floor-section">
        <h2 className="subheading">View Floor</h2>
        <select value={activeFloor} onChange={(e) => changeFloor(e.target.value)}>
          {floors.map(f => (<option key={f.name} value={f.name}>{f.displayName}</option>))}
        </select>
      </div>
      <div className="search-section">
        <h2 className="subheading">Shop</h2>
        <div className="search-wrapper">
          <input
            type="text"
            value={searchTerm}
            placeholder="Search"
            onChange={(e) => setSearchTerm(e.target.value)}
            className="shop-search"
          />
        </div>
      </div>
      <div className={`tenant-list ${searchTerm ? 'hide-categories' : ''}`}>
        <button
          className={`tenant-list-button ${showList ? 'up' : 'down'}`}
          onClick={() => setShowList(!showList)}
        >
          {searchTerm ? 'Tenants' : 'Categories'}
        </button>
        <div className={`tenant-list-wrap ${showList ? 'show' : 'hide'}`}>
          {
              Object.entries(list).map(([id, tenants]) => (
                <React.Fragment key={id}>
                  <h3 className="category-heading subheading">{categories.find(c => c.id === id).name}</h3>
                  <div className="category-listings">
                    {
                      tenants
                        .filter(t => {
                          return t.name.toLowerCase().includes(searchTerm.toLowerCase())
                        })
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map(t => {
                          const suiteVal = t.suite.textfields.suitenum || t.suite.textfields.label_id
                          const isActive = `${activeTenant}`.toLowerCase() === `${suiteVal}`.toLowerCase()
                          return (
                            <button
                              key={`${t.suite.textfields.label_id}-${t.floor.textfields.label_id}-${t.name}`}
                              className={`tenant-button ${isActive ? 'active' : ''}`}
                              onClick={() => changeTenant(suiteVal)}
                            >
                              {t.name}
                              {isActive && <div className='read-more'>
                                  <div className='readMore' onClick={() => goToTenant(t)}>Read more ↗</div>
                                </div>}
                            </button>
                          )
                        })
                    }
                  </div>
                </React.Fragment>
              ))
          }
        </div>
      </div>
    </div>
  )

  return (
    <div className="mapView">
      {sidebar}
      <Map
        tenants={tenants}
        activeFloor={activeFloor}
        activeTenant={activeTenant}
        clickHandler={clickHandler}
      />
    </div>
  );
}

export default MapView
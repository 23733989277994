import React from 'react'
import TenantBlock from './TenantBlock'

const ListView = ({ tenants, goToTenant }) => {
  const scrollToLetter = (letter) => {
    const element = document.getElementById(letter)

    if (!element) {
      return
    }

    element.scrollIntoView()
  }

  const groups = [...'ABCDEFGHIJKLMNOPQRSTUVWXYZ']
  const groupedTenants = {}

  groups.forEach(g => {
    const tenantsInGroup = tenants.filter(t => t.name.toUpperCase().startsWith(g))

    if (tenantsInGroup.length > 0) {
      groupedTenants[g] = {
        header: g,
        tenants: tenantsInGroup
      }
    }
  })

  const nonAlphaTenants = tenants.filter(t => !groups.includes(t.name.toUpperCase()[0]))
  groups.unshift('#')

  if (nonAlphaTenants.length > 0) {
    groupedTenants['#'] = {
      header: '#',
      tenants: nonAlphaTenants
    }
  }

  const selector = (
    <div className='selector alpha'>
      {groups.map(l => <button
        disabled={!groupedTenants.hasOwnProperty(l)}
        onClick={() => scrollToLetter(l)}
        key={l}
      >{l}</button>)}
    </div>
  )

  const finalTenants = groupedTenants

  return (
    <>
      {selector}
      <div className='tenantList'>
        {Object.entries(finalTenants).sort((a, b) => {
          const [keyA] = a
          const [keyB] = b

          return keyA.localeCompare(keyB)
        }).map(([k, s]) => (
          <div
            className='section'
            id={k}
            key={k}
          >
            <TenantBlock
              header={s.header}
            />
            {s.tenants.map(t => <TenantBlock
              key={t._id}
              tenant={t}
              goToTenant={goToTenant}
            />)}
          </div>
        ))}
      </div>
    </>
  )
}

export default ListView
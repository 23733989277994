import React, { useState, useEffect } from 'react';

import MapView from './components/MapView'
import ListView from './components/ListView'
import TenantView from './components/TenantView'
import { ReactComponent as MapIcon } from './icons/map-solid.svg'
import { ReactComponent as CaretOpenIcon } from './icons/caret-down.svg'
import { ReactComponent as CloseIcon } from './icons/close.svg'
import { ReactComponent as ListIcon } from './icons/list-ul-solid.svg'

import './css/App.scss';
import './fonts/ACaslonPro-Bold.otf'
import './fonts/Mark-Heavy.ttf'
import './fonts/Mark-Medium.ttf'

function App() {
  const [tenantData, setTenantData] = useState()
  const [page, setPage] = useState('list')
  const [category, setCategory] = useState('all')
  const [selectOpen, setSelect] = useState(false)
  const [tenantPage, setTenantPage] = useState()
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const onResize = () => {
      if (window.innerWidth < 768) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    }

    onResize()

    const l = window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', l)
    }
  }, [])

  useEffect(() => {
    const baseURL = 'https://cms-v2.yourhere.ca'
    const accountid = '641075889864c9045d5b5f38'
    const key = '~YdL5FZ1wAnFDM_HCj-K-v+k'
    // const baseURL = 'http://localhost:80'
    // const accountid = '63c1f047cfa7ac4c74209dab'
    // const key = 'DjB-WsfnV7Y_4_i4HdzemOQY'

    const getTenantData = async () => {
      try {
        const res = await fetch(`${baseURL}/v1/mapdata/tenants/${accountid}`, {
          method: 'GET',
          headers: {
            'yrh-integration-api-key': key
          }
        })
  
        const data = await res.json()
        if (data) {
          setTenantData(data)
        }
      } catch (err) {
        console.error(err)
      }
    }

    if (!tenantData) {
      getTenantData()
    }

    const urlID = new URLSearchParams(document.location.search).get('t')

    if (urlID && tenantData) {
      const matchingTenant = tenantData.tenants.find(t => t.name === urlID)

      if (!matchingTenant) {
        return
      }

      goToTenant(matchingTenant)
    }
  }, [tenantData])

  if (!tenantData) {
    return (
      <div className={`wrapper ${isMobile ? 'mobile' : ''}`}>
        <div className='loading'>
          Loading...
        </div>
      </div>
    )
  }

  const goToTenant = (tenant) => {
    setPage('tenant')
    setCategory('all')
    setTenantPage(tenant)
  }

  const viewMap = () => {
    setCategory('all')
    setSelect(false)
    setPage('map')
  }

  const toggleSelect = (e) => {
    setSelect(!selectOpen)
    e.preventDefault()
    e.stopPropagation()
  }

  const changeCategory = (id) => {
    setPage('list')
    setCategory(id)
  }

  const { tenants, categories } = tenantData

  const parsedCategories = tenants
    .reduce((acc, curr) => {
      const cats = curr.categories || []
      cats.forEach(c => {
        if (acc.includes(c)) {
          return
        }

        acc.push(c)
      })

      return acc
    }, [])
    .map(c => {
      const matchingLabel = categories.find(cat => cat.label_id === c) || {}
      return {
        id: c,
        name: matchingLabel.name
      }
    })

  const filteredTenants = tenants.filter(t => category === 'all' || t.categories.includes(category))

  const pages = {
    list: {
      name: isMobile ? 'Stores' : 'All Stores',
      component: (
        <ListView
          tenants={filteredTenants}
          categories={parsedCategories}
          goToTenant={goToTenant}
          mode='alpha'
        />
      )
    },
    categories: {
      name: isMobile ? 'Categories' : 'By Category',
      component: (
        <ListView
          tenants={tenants}
          categories={parsedCategories}
          goToTenant={goToTenant}
          mode='cat'
        />
      )
    },
    map: {
      name: isMobile ? 'Map' : 'View Map',
      icon: <MapIcon />,
      component: (
        <MapView
          tenants={tenants}
          categories={parsedCategories}
          isMobile={isMobile}
          goToTenant={goToTenant}
        />
      )
    }
  }

  const tenantView = <TenantView
    tenant={tenantPage}
    tenants={tenants}
    categories={parsedCategories}
    isMobile={isMobile}
  />

  const headers = {
    tenant: tenantPage && tenantPage.name
  }

  const header = headers[page] || 'Find a Store'
  const pageComponent = pages[page] ? pages[page].component : tenantView

  const cat = parsedCategories.find(c => c.id === category)

  const categoryDropdown = (
    <div
      className={`selectWrapper ${(selectOpen || page === 'list') ? 'active' : ''}`}
      onClick={() => setPage('list')}
    >
      <span className='prefix'>Category</span>
      <div className='select' onClick={(e) => toggleSelect(e)}>
        <span className='categoryName'>{cat ? cat.name : 'all'}</span>
        {selectOpen ? <CloseIcon /> : <CaretOpenIcon />}
        {selectOpen ? (
          <div className='options'>
            <div onClick={() => changeCategory('all')}>All</div>
            {parsedCategories.map(g => <div onClick={() => changeCategory(g.id)} key={g.id}>{g.name}</div>)}
          </div>
        ) : null}
      </div>
    </div>
  )

  return (
    <div className={`wrapper ${isMobile ? 'mobile' : ''}`} onClick={() => setSelect(false)}>
      <div className={`pageList ${page}`}>
        <h1 className={header.length > 40 ? 'long' : ''} >{header}</h1>
        {page === 'list' ? categoryDropdown : null}
        {(page !== 'list' || !isMobile) ? (
          <button
              className={page === 'list' ? 'active' : ''}
              onClick={() => setPage('list')}
          >
            <ListIcon />
            Store List
          </button>
        ) : null}
        <button
          className={page === 'map' ? 'active' : ''}
          onClick={() => viewMap()}
        >
          <MapIcon />
          View Map
        </button>
      </div>
      <div className={`pageSection ${page}`}>{pageComponent}</div>
    </div>
  )
}

export default App;

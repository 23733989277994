import React, { useState } from 'react'
import Map from './Map'

import '../css/Tenant.scss';

import floorDict from '../floorDict.json'

const TenantView = ({ tenant, tenants, categories }) => {
  const [copied, setCopied] = useState(false)

  const {
    description = '',
    fileid,
    categories: cats = [],
    floor = {},
    suite = {},
    name,
    url = ''
  } = tenant

  const floorVal = floor.textfields && floor.textfields.label_id
  const suiteVal = suite.textfields && (suite.textfields.suitenum || suite.textfields.label_id)

  const phoneMatch = description.match(/<p>([0-9-]*)<\/p>/)
  const number = phoneMatch && phoneMatch[1]

  const logoSource = fileid ? `https://cms-v2.yourhere.ca/containers/${fileid}` : './noLogo.png'
  const activeFloor = floorDict[floorVal]

  const copyLink = () => {
    const baseURL = 'https://ecc.yourhere.biz?t='
    navigator.clipboard.writeText(`${baseURL}${encodeURIComponent(name)}`).then(() => {
      // Success
      setCopied(true)
    }, (err) => {
      // Error
      console.error(err)
    })
  }

  return (
    <div className='tenantWrapper'>
      <div className='side'>
        <div className='logo'>
          <img src={logoSource} alt={`Logo for ${name}`}/>
        </div>
        <div className='description'>
          {number || url ? <div className='block'>
            <h2>Contact Us</h2>
            {number ? <div className='text'>{number}</div> : null}
            {url ? <div className='text link'><a target='_blank' rel='noopener noreferrer' href={url}>Visit Website</a></div> : null}
          </div> : null}
          <div className='block'>
            <h2>Category</h2>
            <div className='text'>{cats.map(c => categories.find(catObj => catObj.id === c).name).join(', ')}</div>
          </div>
          {floor.name ? <div className='block'>
            <h2>Level</h2>
            <div className='text'>{floor.name}</div>
          </div> : null}
        </div>
      </div>
      <div className='tenant'>
        {description ? <div className='descriptionBlock'>
          <h2>About Us</h2>
          <div className='about' dangerouslySetInnerHTML={{ __html: description }}></div>
          <div className='share'>
            <button
              onClick={copyLink}
            >{copied ? 'Link Copied' : 'Copy Link'}</button>
          </div>
        </div> : null}
        <Map
          tenants={tenants}
          activeFloor={activeFloor}
          activeTenant={suiteVal}
        />
      </div>
    </div>
  )
}

export default TenantView
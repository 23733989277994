"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _fileSaver = _interopRequireDefault(require("file-saver"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Config = function Config(sceneWrapper) {
  var _this = this;

  _classCallCheck(this, Config);

  this.getNodes = function () {
    return _this.scene.allNodes.filter(function (node) {
      return node.nodeType === 'node' || node.nodeType === 'initialNode';
    });
  };

  this.getShops = function () {
    return _this.scene.assets;
  };

  this.getDirectory = function () {
    return {
      cameraDirectoryPos: _this.scene.camera.position || '',
      cameraNorth: _this.scene.cameraNorth,
      nodeSize: _this.sceneWrapper.nodeSize,
      nodeColor: _this.sceneWrapper.nodeColor,
      floors: _this.scene.floors,
      floorSpace: _this.sceneWrapper.floorSpace
    };
  };

  this.loadCmsData = function (cmsTenants) {
    _this.sceneWrapper.log('Loading CMS data', cmsTenants);

    _this.sceneWrapper.timeStart('Load CMS Data');

    _this.scene.assets.forEach(function (a) {
      if (!a.cmsID) {
        return;
      }

      var matchingTenant = cmsTenants.find(function (tenant) {
        var tSuite = tenant.suite && tenant.suite.textfields ? "".concat(tenant.suite.textfields.suitenum || tenant.suite.textfields.label_id) : "".concat(tenant.suitenum);
        return tSuite.toLowerCase() === "".concat(a.cmsID).toLowerCase();
      });

      if (!matchingTenant) {
        return;
      }

      matchingTenant.matched = true;
      a.cms = _objectSpread({}, matchingTenant);
    });

    _this.sceneWrapper.timeEnd('Load CMS Data');
  };

  this.updateShopsFromConfig = function () {
    var arrayShops = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    return new Promise(function (resolve) {
      _this.sceneWrapper.log('Updating Shops from config', arrayShops);

      _this.sceneWrapper.timeStart('Load shops from config'); // Apply transformation to shops


      Promise.all(arrayShops.map(function (shop) {
        return new Promise(function (innerResolve) {
          if (shop.assetId) {
            var foundShop = _this.scene.getMeshByName(shop.assetId);

            if (foundShop) {
              var foundAsset = _this.scene.assets.find(function (asset) {
                return asset.assetId === shop.assetId;
              });

              foundAsset.displayName = shop.displayName || foundAsset.displayName;
              foundAsset.imageSource = shop.imageSource || foundAsset.imageSource;
              foundAsset.cmsID = shop.cmsID || foundAsset.cmsID;
              foundAsset.assetType = shop.assetType || foundAsset.assetType;
              foundAsset.customType = shop.customType || foundAsset.customType;
              foundAsset.showName = shop.showName || foundAsset.showName;
              foundAsset.showLogo = shop.showLogo || foundAsset.showLogo;
              foundAsset.transferFloors = shop.transferFloors || foundAsset.transferFloors;
              foundAsset.uncharted = shop.uncharted || foundAsset.uncharted;
              foundAsset.orientation = shop.orientation || foundAsset.orientation;
              foundAsset.scale = shop.scale || foundAsset.scale;
            }
          }

          innerResolve();
        });
      })).then(function () {
        _this.sceneWrapper.timeEnd('Load shops from config');

        resolve();
      });
    });
  };

  this.createNodeFromConfig = function () {
    var arrayNs = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    return new Promise(function (resolve) {
      _this.sceneWrapper.log('Creating nodes from config', arrayNs);

      _this.sceneWrapper.timeStart('Load nodes from config');

      arrayNs.forEach(function (node) {
        var newNode = {
          id: node.nodeId,
          position: node.position,
          targetNodes: node.targetNodes,
          targetAssets: node.targetAssets,
          orientation: node.orientation,
          terminalID: node.terminalID,
          floor: node.floor,
          floorRotation: node.floorRotation || 0,
          assetType: node.assetType,
          customType: node.customType
        };

        if (node.floor) {
          _this.sceneWrapper.setActiveFloor([node.floor], false);
        }

        if (node.nodeType === 'node') {
          _this.sceneWrapper.addNode(newNode, false, true);
        } else {
          _this.sceneWrapper.addInitialNode(newNode);
        } // this.sceneWrapper.showLinks({ name: node.nodeId })

      }); // reset floor rotation/visiblity

      _this.sceneWrapper.resetRotation();

      _this.sceneWrapper.setActiveFloor([], false);

      _this.sceneWrapper.timeEnd('Load nodes from config');

      resolve();
    });
  };

  this.updateCameraFromConfig = function (info) {
    _this.sceneWrapper.log('Loading camera data', info);

    if (info) {
      var _info$cameraNorth = info.cameraNorth,
          cameraNorth = _info$cameraNorth === void 0 ? 0 : _info$cameraNorth;
      _this.scene.cameraNorth = cameraNorth;

      _this.sceneWrapper.rotateFloors(cameraNorth);
    }
  };

  this.loadConfiguration = function (config) {
    return new Promise(function (resolve) {
      _this.sceneWrapper.log('Loading config', config);

      if (config.directory.nodeSize) {
        _this.sceneWrapper.nodeSize = config.directory.nodeSize;
      }

      if (config.directory.nodeColor) {
        _this.sceneWrapper.nodeColor = config.directory.nodeColor;
      }

      if (config.directory.floors) {
        _this.scene.floors = config.directory.floors;

        _this.sceneWrapper.setActiveFloor([], false);
      }

      resolve(_this.createNodeFromConfig(config.nodes).then(function () {
        return _this.updateShopsFromConfig(config.shops);
      }).then(function () {
        return _this.updateCameraFromConfig(config.directory);
      }));
    });
  };

  this.saveConfiguration = function (fileName) {
    var configObj = {
      nodes: _this.getNodes(),
      shops: _this.getShops(),
      directory: _this.getDirectory()
    };
    var blob = new Blob([JSON.stringify(configObj)], {
      type: 'application/json'
    });
    (0, _fileSaver["default"])(blob, "".concat(fileName, ".json"));
  };

  this.saveCamera = function (rotation) {
    _this.scene.cameraNorth = rotation.y;

    _this.scene.allNodes.filter(function (n) {
      return n.nodeType === 'initialNode';
    }).forEach(function (n) {
      var mesh = _this.sceneWrapper.scene.getMeshByName(n.id);

      if (mesh) {
        _this.sceneWrapper.updateTerminalOrientation(n.orientation, mesh);
      }
    });
  };

  this.sceneWrapper = sceneWrapper;
  this.scene = sceneWrapper.scene;
};

exports["default"] = Config;
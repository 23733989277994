import React from 'react'
import { Map3D } from '@yourhere/yourhere-core'
import { Config, SceneWrapper } from '@yourhere/yourhere-3dmapping'

import config from '../ecc-config.json'

const Map = ({ tenants, activeFloor, activeTenant, clickHandler }) => {
  return (
    <Map3D
      babylonFilepath='/models/ecc.babylon'
      mapping={{
        config: Config,
        sceneWrapper: SceneWrapper
      }}
      activeFloor={activeFloor}
      activeTenant={activeTenant}
      config={config}
      tenantData={tenants}
      options={{
        hideControls: false,
        hideFloorLabels: true,
        hideRotateControls: true,
        hideNorthMarker: false,
        showRouteSelector: false,
        clickToHighlight: !!clickHandler,
        orthoScale: 0.05,
        cameraBeta: Math.PI / 4,
        upperZoomLimit: 0.07,
        lowerZoomLimit: 0.008,
        disableRotationX: true,
        disableRotationY: true,
        logoKey: 'maplogo',
        labelFont: 'Verdana',
        maxTextLabelSize: 0.01,
        centerActiveTenant: true,
        northMarker: './icons/north.svg',
        clickHandler
      }}
      primaryHighlight="#ffa600"
      secondaryHighlight="#ffd280"
      backgroundColor="#eeeeee"
    />
  )
}

export default Map
import React from 'react'

const TenantBlock = ({ tenant, header, goToTenant }) => {
  if (header) {
    return (
      <div className='tenantBlock header' key={header}>
        <div className='content'>
          {header}
        </div>
      </div>
    )
  }

  const {
    description = '',
    name,
    fileid,
    _id,
    floor = {}
  } = tenant

  const phoneMatch = description.match(/<p>([0-9-]*)<\/p>/)
  const number = phoneMatch && phoneMatch[1]

  const logoSource = fileid ? `https://cms-v2.yourhere.ca/containers/${fileid}` : './noLogo.png'

  return (
    <div className='tenantBlock' key={_id} onClick={() => goToTenant(tenant)}>
      <div className='logo'>
        <img src={logoSource} alt={`Logo for ${name}`}/>
      </div>
      <div className={`name ${name.length > 35 ? 'long' : 'short'}`}>{name}</div>
      <div className='phone'>
        {number ? <>
          <img src="./icons/phone-solid.svg" alt=''/>
          {number}
        </> : null}
      </div>
      <div className='location'>
        <img src="./icons/layer-group-solid.svg" alt=''/>
        {floor.name}
      </div>
    </div>
  )
}

export default TenantBlock